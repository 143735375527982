<template>
  <div class="row">
    <div class="col-12">
      <h5 style="color: #428a94; font-weight: bold">Tambah Kamar</h5>
      <a-form :form="form">
        <div class="row">
          <div class="col-4 col-sm-12 my-2">
            <a-form-item>
              <p>Kelas Kamar<span style="color: #dc3545">*</span></p>
              <div class="row px-3 mt-2">
                <a-select class="col-12 col-sm-6 col-md-4" v-model="roomClass">
                  <a-select-option value="">Pilih kelas kamar</a-select-option>
                  <a-select-option
                    v-for="(item, index) in dataKelasKamar"
                    :key="index"
                    :value="item.id"
                    >{{ item.name }}</a-select-option
                  >
                </a-select>
              </div>
            </a-form-item>
          </div>

          <div class="col-12">
            <p class="m-0">Nama Kamar<span style="color: #dc3545">*</span></p>
            <span class="m-0" style="font-size: 12px; color: #667085">
              Nama harus unik dari nama kamar lain di master kamar
            </span>
            <div class="row px-3">
              <a-input
                placeholder="Nama Kelas Kamar"
                class="col-12 col-sm-6 col-md-4"
                v-model="namaKamar"
              ></a-input>
            </div>
          </div>
          <div class="col-12 mt-5">
            <p class="m-0">Kamar Khusus<span style="color: #dc3545">*</span></p>
            <div class="mt-3">
              <a-radio-group v-model="genderType">
                <a-radio value="Pria/Wanita">Pria/Wanita</a-radio>
                <a-radio value="Pria">Pria</a-radio>
                <a-radio value="Wanita">Wanita</a-radio>
              </a-radio-group>
            </div>
          </div>
          <div class="col-4 mt-5 pr-0">
            <p class="">Nomor Tempat Tidur</p>

            <div
              class="d-flex mt-2"
              v-for="(item, index) in nomorTempatTidur"
              :key="`A` + index"
            >
              <a-input v-model="item.name" />
              <div
                class="m-0 ml-3 d-flex align-items-center justify-content-center h4"
              >
                <img
                  src="resources/images/x-black.svg"
                  alt="Icon"
                  style="height: 24px; width: 24px; cursor: pointer"
                  @click="deleteNomorTempatTidur(index)"
                />
              </div>
            </div>
            <div
              class="mt-2 h6 mb-3"
              style="color: #428a94"
              @click="addNomorTempatTidur"
            >
              <a>+ Tambah Tempat Tidur</a>
            </div>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-12">
            <a-button
              type="primary"
              class="mt-2 d-flex flex-row align-items-center justify-content-center"
              style="width: 230px; border-radius: 26px"
              size="large"
              @click="handleAddModal"
            >
              <img
                src="resources/images/save.png"
                alt="Icon"
                style="height: 18px; width: 18px"
              />
              <span style="margin-left: 5px">Simpan Kelas Kamar</span></a-button
            >
          </div>
        </div>
      </a-form>
      <a-modal
        v-model="modalAddVisible"
        title="Tambah kelas kamar"
        centered
        :footer="null"
      >
        <p>
          Mohon cek kembali data kelas kamar sebelum menyimpan formulir. Setelah
          berhasil disimpan, kelas kamar baru akan dibuat.
        </p>
        <div class="d-flex justify-content-end mt-4">
          <button
            class="mr-2 d-flex align-items-center justify-content-center btn btn-outline-primary edit-button"
            style="width: 50%; border-radius: 26px"
            @click.prevent="handleCloseModal"
          >
            <span class="ml-2">Kembali</span>
          </button>
          <button
            @click.prevent="submitData"
            class="d-flex align-items-center justify-content-center btn btn-primary delete-button"
            style="width: 50%; border-radius: 26px"
          >
            <span class="ml-2">Ya</span>
          </button>
        </div>
      </a-modal>
    </div>
  </div>
</template>

<script>
import { getRoomClass, getRoom, createRoom } from "@/services/axios/api/rooms"
import { notification } from "ant-design-vue"
// import { InfoCircleOutlined } from "@ant-design/icons-vue"
export default {
  components: {
    // InfoCircleOutlined,
  },
  data: function () {
    return {
      jenisPerawatanOptions: [
        {
          label: "Rawat Non-Intensif",
          value: "Rawat Non-Intensif",
        },
        {
          label: "Rawat Intensif",
          value: "Rawat Intensif",
        },
      ],
      jenisPerawatan: "",
      namaKamar: "",
      bhpRuangan: "",
      biayaAdministrasi: "",
      tarifKelasKamarUmum: "",
      tarifKelasKamarBPJS: "",
      tarifKelasKamarAsuransiLain: "",
      tarifPerawatanUmum: "",
      tarifPerawatanBPJS: "",
      tarifPerawatanLainnya: "",
      isNamaKamarEmpty: true,
      isNamaKamarExist: true,
      modalAddVisible: false,
      nomorTempatTidur: [],
      dataKelasKamar: [],
      dataKamar: [],
      roomClass: "",
      genderType: "",
      form: this.$form.createForm(this, { name: "coordinated" }),
    }
  },
  created() {
    this.getName()
    this.fetchData()
  },

  methods: {
    addNomorTempatTidur() {
      this.nomorTempatTidur.push({
        id: this.nomorTempatTidur.length + 1,
        order: this.nomorTempatTidur.length + 1,
        name: this.nomorTempatTidur.length + 1,
      })
    },
    deleteNomorTempatTidur(idx) {
      this.nomorTempatTidur.splice(idx, 1)
    },

    handleAddModal() {
      this.modalAddVisible = true
    },
    handleCloseModal() {
      this.modalAddVisible = false
    },

    getName() {
      const name = localStorage.getItem("name")
      this.name = name
    },
    async fetchData() {
      const { data } = await getRoom()
      const { data: dataClass } = await getRoomClass()
      // console.log(data.data)
      this.dataKamar = data.data
      this.dataKelasKamar = dataClass.data
    },

    async submitData() {
      let isQualified = true

      this.isNamaKamarEmpty = true
      this.isNamaKamarExist = true

      if (!this.namaKamar) {
        this.isNamaKamarEmpty = false
        isQualified = false
      } else {
        const existingRoomClass = this.dataKamar.filter(
          (e) => e.name === this.namaKamar,
        )
        if (existingRoomClass.length) {
          this.isNamaKamarExist = false
          isQualified = false
        }
      }

      if (isQualified) {
        const { data } = await createRoom({
          name: this.namaKamar,
          roomClassId: this.roomClass,
          genderType: this.genderType,
          beds: this.nomorTempatTidur,
        })
        // console.log(data)
        if (data.data.id) {
          notification.success({
            message: data.message,
            description: "",
            duration: 5,
          })
          this.$router.push("/kamar")
        }
      } else {
        notification.warning({
          message: "Gagal Membuat Pemeriksaan",
          description:
            "Inputan masih belum memenuhi requirement yang diperlukan",
          duration: 5,
        })
      }
    },
  },
}
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
}

.header-text {
  font-size: 18px;
  text-align: center;
  color: #428a94;
  font-weight: bold;

  @media (max-width: 768px) {
    font-size: 16px;
  }
}
</style>
